import React from 'react';
import my_icon from './my_icon.jpeg';
import current_company from './current_company.webp';
import './App.css';

import { BiLogoFigma, BiLogoGraphql, BiLogoCPlusPlus } from "react-icons/bi";
import { SiAzuredevops, SiExpo, SiTypescript, SiCsharp, SiAwsamplify, SiMongodb, SiAmazondynamodb, SiCypress, SiSelenium, SiGmail, SiLinkedin, SiInstagram } from "react-icons/si";
import { FaJs, FaJava, FaNodeJs, FaAws } from "react-icons/fa";
import { TbSql } from "react-icons/tb";
import { IoLogoGithub } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";

function App() {

    return (
        <div className="App">
            <header className='flex gap-2 items-center py-3 px-5'>
                <img className='w-6 h-6 rounded-full' src={my_icon} />
                <p className='text-xl text-slate-900 font-extrabold tracking-tight'>ndivhuwo.com</p>
                <div className='flex-1'></div>
                <div className='flex gap-6'>
                    <a href='mailto:ndivhuwoteenage@gmail.com' className='text-slate-900 hover:text-slate-400'>
                        <MdOutlineMail size={24} />
                    </a>
                    <SiLinkedin size={20} onClick={() => window.open("https://www.linkedin.com/in/ndivhuwo-mutavhatsindi-778a18237" )} className='text-slate-900 hover:text-slate-400 hover:cursor-pointer' />
                    <SiInstagram size={20} onClick={() => window.open("https://www.instagram.com/ndivhuwo.mutavhatsindi")} className='text-slate-900 hover:text-slate-400 hover:cursor-pointer' />
                    <h1 className='hidden md:block text-slate-300 text-sm font-light tracking-tighter'>GET IN TOUCH</h1>
                </div>
            </header>

            <body className='pt-20 sm:pt-24 lg:pt-32 px-6 md:px-24 2xl:px-72 items-center'>
                <h1 className='text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center'><span className='text-sky-500'>Test Analyst</span> & Software Developer Focused on Quality and Innovation.</h1>
                <p className='mt-6 text-lg text-slate-600 text-center'>Ensuring Quality and Building Reliable Solutions through <span className='text-sky-500'>Rigorous Testing</span> and <span className='text-sky-500'>Innovative Software Development</span>.</p>
                
                <div className='flex flex-col sm:flex-row items-center justify-center mt-6'>
                    <img className='w-14 h-14 rounded-full' src={my_icon} />
                    <div className='text-center sm:text-left mt-4 sm:mt-0 sm:ml-4'>
                        <h1 className='text-lg text-slate-900 font-semibold'>Mutavhatsindi Ndivhuwo</h1>
                        <h2 className='text-sm text-slate-600'>Test Analyst & Software Developer</h2>
                    </div>
                </div>

                <p className='mt-6 text-lg text-slate-600'><span className='text-sky-500 font-semibold'>console.log("</span>Hello! I’m Mutavhatsindi Ndivhuwo, a dedicated Test Analyst and Software Developer with a BSc in <span className='text-sky-500'>Computer Science</span> and <span className='text-sky-500'>Informatics</span> from the <span className='text-sky-500'>University of Johannesburg</span>. With close to two years of hands-on experience, I specialize in delivering high-quality, reliable software solutions through both rigorous testing and full-stack development. Skilled in a range of frameworks and programming languages, I’m adept at both manual and automation testing, ensuring that every application I work on meets the highest standards. Whether it’s coding from scratch or refining existing systems, I bring a blend of analytical insight and development expertise to each project.<span className='text-sky-500 font-semibold'>");</span></p>
                
                <div className='mt-20 text-center sm:text-left'>
                    <h1 className='text-3xl sm:text-4xl text-slate-900 font-extrabold tracking-tight'>Skills</h1>
                    <h2 className='mt-6 text-lg sm:text-xl font-semibold text-slate-600'>Development</h2>
                    
                    <div className='mt-6 grid grid-cols-2 gap-5'>
                        <div className='flex'>
                          <BiLogoFigma size={30} color='#0ACF83' />
                            <div className='ml-3 w-full'>
                                <h3>Figma</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiExpo size={30} color='#1f1f1f' />
                            <div className='ml-3 w-full'>
                                <h3>Expo CLI</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[85%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <FaJs size={30} color='#F7DF1E' />
                            <div className='ml-3 w-full'>
                                <h3>JavaScript</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[80%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiTypescript size={30} color='#007ACC' />
                            <div className='ml-3 w-full'>
                                <h3>TypeScript</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[80%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                          <BiLogoCPlusPlus size={30} color='#00599C' />
                            <div className='ml-3 w-full'>
                                <h3>C++</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[55%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiCsharp size={30} color='purple' />
                            <div className='ml-3 w-full'>
                                <h3>C#</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[65%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                          <SiAzuredevops size={30} color='#007ACC' />
                            <div className='ml-3 w-full'>
                                <h3>DevOps</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <BiLogoGraphql size={30} color='#E535AB' />
                            <div className='ml-3 w-full'>
                                <h3>GraphQL</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <FaJava size={30} color='#5382a1' />
                            <div className='ml-3 w-full'>
                                <h3>Java</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[65%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <FaNodeJs size={30} color='#8CC84B' />
                            <div className='ml-3 w-full'>
                                <h3>NodeJS</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[65%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <FaAws size={30} color='#FF9900' />
                            <div className='ml-3 w-full'>
                                <h3>AWS</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[65%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiAwsamplify size={30} color='#FF9900' />
                            <div className='ml-3 w-full'>
                                <h3>AWS Amplify</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <TbSql size={30} color='#00758F' />
                            <div className='ml-3 w-full'>
                                <h3>SQL</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[67%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiMongodb size={30} color='#47A248' />
                            <div className='ml-3 w-full'>
                                <h3>MongoDB</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[60%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiAmazondynamodb size={30} color='#405C8C' />
                            <div className='ml-3 w-full'>
                                <h3>DynamoDB</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <IoLogoGithub size={30} color='#181717' />
                            <div className='ml-3 w-full'>
                                <h3>GitHub</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[60%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className='mt-10 text-lg sm:text-xl font-semibold text-slate-600'>Testing</h2>
                        
                    <div className='mt-6 grid grid-cols-2 gap-5'>
                        <div className='flex'>
                          <SiCypress size={30} color='#4ECDC4' />
                            <div className='ml-3 w-full'>
                                <h3>Cypress</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[60%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <SiSelenium size={30} color='#43B02A' />
                            <div className='ml-3 w-full'>
                                <h3>Selenium</h3>
                                <div className='mt-2 flex-1 h-1 sm:h-2 bg-gray-200 rounded-full'>
                                    <div className='h-full w-[70%] bg-sky-500 rounded-full' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-20 text-center sm:text-left'>
                        <h1 className='text-3xl sm:text-4xl text-slate-900 font-extrabold tracking-tight'>Projects</h1>
                        <div className='mt-6 flex py-3 text-left'>
                            <img className='w-12 h-12 rounded-md border border-gray-300' src='https://www.bubblesocialnetworking.com/static/media/logo.469fcc79432e13f6b343.png' />
                            <div className='flex-1 mx-3'>
                                <h2 className='font-medium'>Bubble Social Networking</h2>
                                <p className='text-slate-600'>Software Deverloper & Test Analyst</p>
                                <a className='text-sm text-sky-500 hover:cursor-pointer hover:underline hover:text-sky-600' onClick={() => window.open("https://www.bubblesocialnetworking.com" )}>www.bubblesocialnetworking.com</a>
                                <h2 className='mt-1 sm:hidden text-sky-500 text-xs font-normal'>01 APR 2021 - PRESENT</h2>
                            </div>
                            <div className='hidden sm:block'>
                                <h2 className='text-sky-500 text-sm font-normal'>01 APR 2021 - PRESENT</h2>
                            </div>
                        </div>
                    </div>

                    <div className='mt-20 text-center sm:text-left'>
                        <h1 className='text-3xl sm:text-4xl text-slate-900 font-extrabold tracking-tight'>Experience</h1>
                        <div className='mt-6 flex py-3 text-left'>
                            <img className='w-12 h-12 rounded-md border border-gray-300' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRemVpnV4NbfWMVoppZPXjLSPjTJZpi9aC-YA&s' />
                            <div className='flex-1 mx-3'>
                                <h2 className='font-medium'>Geeks4Learning</h2>
                                <p className='text-gray-600'>Test Analyst</p>
                                <h2 className='mt-1 sm:hidden text-sky-500 text-xs font-normal'>13 MAR 2023 - 31 JAN 2024</h2>
                            </div>
                            <div className='hidden sm:block'>
                                <h2 className='text-sky-500 text-sm font-normal'>13 MAR 2023 - 31 JAN 2024</h2>
                            </div>
                        </div>

                        <div className='flex py-3 text-left'>
                            <img className='w-12 h-12 rounded-md border border-gray-300' src={current_company} />
                            <div className='flex-1 mx-3'>
                                <h2 className='font-medium'>Current Company</h2>
                                <p className='text-slate-600'>Test Analyst</p>
                                <h2 className='mt-1 sm:hidden text-sky-500 text-xs font-normal'>13 MAR 2024 - PRESENT</h2>
                            </div>
                            <div className='hidden sm:block'>
                                <h2 className='text-sky-500 text-sm font-normal'>13 MAR 2024 - PRESENT</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </body>

            <footer className='mt-10 bg-black px-10 py-5'>
                <p className='text-white text-sm sm:text-base font-light tracking-tighter'>ndivhuwo.com</p>
            </footer>
        </div>
    );
};

export default App;
